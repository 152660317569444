import { Box, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { getPostUrl } from '../../auth/auth-utils';
import {
  professionalPlanBenefits,
  standardPlanBenefits,
  studentsPlanBenefits,
} from './benefitsByPlan';
import { createStyles, makeStyles } from '@mui/styles';
import { Plan, PlanFrequency } from '../../../shared/types/generated';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { checkoutPlanSubscription } from '../plan-actions';
import { Loader } from '../../../shared/components/Loader';

type PlanProps = {
  plan: Plan;
};
//theme: Theme
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    bannerPro: {
      backgroundImage: 'url("/banner-pro.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStudent: {
      backgroundImage: 'url("/banner-student.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStandard: {
      backgroundImage: 'url("/banner-standard.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
  }),
);
export const USPeso = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
});
export const PlanNewCard: React.FC<PlanProps> = ({ plan }) => {
  const { user } = useAuth();
  const classes = useStyles();
  let benefits: (string | ReactNode)[] = [];

  const [callCheckoot, loading] = useCallAction(checkoutPlanSubscription, {
    onError: (error) => {
      console.error(error);
    },
  });

  switch (plan.price) {
    case '1000':
      benefits = professionalPlanBenefits;
      break;
    case '100':
      benefits = standardPlanBenefits;
      break;
    case '55':
      benefits = studentsPlanBenefits;
      break;
  }

  return (
    <Box
      mb={2}
      width={'328px'}
      fontFamily={'acumin-pro'}
      borderRadius={'15px'}
      overflow={'hidden'}
      component={'div'}
      style={{ boxShadow: '0px 3px 8px #00000029' }}
    >
      <Box
        style={{ background: '#FF3E00' }}
        height={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          fontFamily={'acumin-pro'}
          fontSize={'20px'}
          fontWeight={700}
          lineHeight={'20px'}
          color={'#ffffff'}
          textAlign={'center'}
        >
          Promoción de verano -50%
        </Typography>
      </Box>
      <Box
        height={'90px'}
        width={'100%'}
        className={
          plan.id === 7
            ? classes.bannerStudent
            : plan.id === 8
            ? classes.bannerStandard
            : classes.bannerPro
        }
      />
      <Typography
        fontFamily={'acumin-pro'}
        textAlign={'center'}
        mt={'10px'}
        fontSize={'24px'}
        lineHeight={'24px'}
        fontWeight={'bold'}
      >
        <span>{plan.title}</span>
      </Typography>
      {plan.sub_title && (
        <Typography
          fontFamily={'acumin-pro'}
          textAlign={'center'}
          lineHeight={'16px'}
          mt={'5px'}
        >
          {plan.sub_title}
        </Typography>
      )}
      <Typography
        fontFamily={'acumin-pro'}
        mt={plan.frequency === PlanFrequency.Year ? '8px' : '24px'}
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'30px'}
        lineHeight={'30px'}
      >
        {USPeso.format(Number(plan?.price))} MXN{' '}
        <span style={{ fontSize: '20px', color: '#414141' }}>
          {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
        </span>
      </Typography>
      <Typography
        fontFamily={'acumin-pro'}
        mt={'3px'}
        style={{ textDecoration: 'line-through' }}
        color={'#FF3C41'}
        lineHeight={'16px'}
        fontWeight={'600'}
        textAlign={'center'}
      >
        {plan.helper_text}
      </Typography>
      <Box display={'flex'} fontFamily={'acumin-pro'} justifyContent={'center'}>
        <Box width={'240px'}>
          <Typography
            mt={'8px'}
            fontFamily={'acumin-pro'}
            fontSize={'14px'}
            textAlign={'center'}
          >
            Pago único, vigente para un{' '}
            {plan.frequency === PlanFrequency.Year ? 'año' : 'mes'}
          </Typography>
          <Typography
            mt={'1px'}
            fontFamily={'acumin-pro'}
            color={'#828282'}
            fontSize={'14px'}
            lineHeight={'18px'}
            textAlign={'center'}
          >
            Posteriormente se hará el cargo por el monto completo de{' '}
            {plan.helper_text}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} mt={'10px'} justifyContent={'center'}>
        <Box
          component={'div'}
          role="button"
          tabIndex={0}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ background: '#FF3C41', cursor: 'pointer' }}
          width={'160px'}
          borderRadius={'20px'}
          height={'35px'}
          onClick={() => {
            if (user) {
              callCheckoot({
                email: user?.email || 'miguelangel2023@gmail.com',
                priceId: plan.stripe?.price_id || '',
                stateUrl: `post_url=${getPostUrl()}`,
              });
              return;
            }
            const redirectUrl = `${
              window.location.origin
            }/planes?post_url=${getPostUrl()}`;
            window.location.href = `${
              window.location.origin
            }/autenticacion?redirect_url=${encodeURIComponent(redirectUrl)}`;
          }}
        >
          <Typography
            fontFamily={'acumin-pro'}
            letterSpacing={'1px'}
            fontSize={'20px'}
            color={'#ffffff'}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            {loading ? <Loader color="secondary" size={20} /> : 'Suscribirme'}
          </Typography>
        </Box>
      </Box>
      <ul
        style={{
          padding: '0px 50px',
          margin: '15px 0px',
          height: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {benefits.map((item, index) => (
          <li
            key={'list' + index}
            style={{ lineHeight: '18px', fontSize: '15px' }}
          >
            {item}
          </li>
        ))}
      </ul>
      <Typography
        fontSize={'12px'}
        color={'#515151'}
        textAlign={'center'}
        pb={'10px'}
      >
        Promoción de verano hasta el 30 de septiembre de 2024.
      </Typography>
    </Box>
  );
};
