import React, { useState } from 'react';
import { FormControl, Input } from '@mui/material';
import { styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledLabel = styled('label')({
  marginBottom: 5,
  fontWeight: 'bold',
});

const StyledInput = styled(Input)({
  border: '1px solid #D5DCE6',
  padding: '5px 10px',
  margin: '0!important',
  borderRadius: 4,
});

const StyledIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
});

type InputDefaultProps = {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string | number | boolean;
  name?: string;
  type?: string;
  icon?: React.ReactElement;
  readOnly?: boolean;
};

export const InputDefault: React.FC<InputDefaultProps> = ({
  readOnly = false,
  label,
  onChange,
  value,
  name,
  type,
  icon,
}) => {
  let defaultIcon = <></>;
  const [passwordType, setPasswordType] = useState<undefined | string>(
    undefined,
  );

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  if (type === 'password') {
    defaultIcon = <StyledIcon onClick={handleVisibility} />;
  }

  return (
    <FormControl fullWidth>
      <StyledLabel htmlFor="my-input">{label}</StyledLabel>
      <StyledInput
        disableUnderline
        id="my-input"
        onChange={onChange}
        type={passwordType || type}
        value={value}
        name={name}
        endAdornment={icon || defaultIcon}
        readOnly={readOnly}
      />
    </FormControl>
  );
};
