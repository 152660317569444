import React, { ReactNode } from 'react';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
} from '@mui/material';
import { ButtonDefault } from '../ButtonDefault';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    button: {
      minWidth: '140px!important',
      height: '38px!important',
    },
  }),
);

type DialogDefaultProps = {
  title: string;
  children: ReactNode;
  handleSuccess?: () => void;
  isOpen: boolean;
  handleClose?: () => void;
  isLoading?: boolean;
  showActions?: boolean;
  showSuccessButton?: boolean;
  maxWidth?: Breakpoint;
  disabledAction?: boolean;
};

export const DialogDefault: React.FC<DialogDefaultProps> = ({
  title,
  children,
  handleSuccess,
  handleClose,
  isOpen,
  isLoading = false,
  showActions = false,
  showSuccessButton = true,
  maxWidth,
  disabledAction,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose && handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...(maxWidth && {
        maxWidth,
        fullWidth: true,
      })}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      {showActions && (
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose && handleClose()}
            className={classes.button}>
            Cancelar
          </Button>
          {showSuccessButton && (
            <ButtonDefault
              onClick={() => handleSuccess && handleSuccess()}
              isLoading={isLoading}
              disabled={disabledAction}
              className={classes.button}>
              Confirmar
            </ButtonDefault>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
