import { createAction } from '@cobuildlab/react-simple-state';
import {
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
} from './plan-events';
import { stripe } from '../../shared/stripe/config';

export const checkoutPlanSubscription = createAction(
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
  async (props: {
    email: string;
    priceId: string;
    stateUrl: string;
  }): Promise<void> => {
    const origin = window.location.origin;
    const url = await window.location.href;

    const session = await stripe.checkout.sessions.create({
      mode: 'subscription',
      locale: 'es',
      customer_email: props.email,
      allow_promotion_codes: true,
      line_items: [
        {
          price: props.priceId,
          quantity: 1,
        },
      ],
      success_url: `${origin}?session_id={CHECKOUT_SESSION_ID}&${props.stateUrl}`,
      cancel_url: `${url}?returningStripe=true`,
    });

    if (!session.url) {
      throw new Error('Error al crear la sesion de pagos.');
    }

    window.location.href = session.url;

    return;
  },
);
